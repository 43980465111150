footer {
    padding: 40px 0;
    background-color: #000639;
}

html[dir="rtl"] footer .contact-item {
    position: relative;
    padding-right: 55px;
}

html[dir="ltr"] footer .contact-item {
    position: relative;
    padding-left: 55px;
}

footer .contact-item-icon,
footer .social-icon {
    position: absolute;
    top: 8px;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 16px;
    // line-height: 34px;
    line-height: 30px;
    color: #000639;
}

html[dir="rtl"] footer .contact-item-icon,
html[dir="rtl"] footer .social-icon {
    right: 5px;
}

html[dir="ltr"] footer .contact-item-icon,
html[dir="ltr"] footer .social-icon {
    left: 5px;
}

footer .social-icon {
    top: 3px;
}

footer .contact-item-icon::before,
footer .social-icon:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    // background-color: #08CE7F;
    background-color: #10a37f;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

footer .contact-item-icon i,
footer .contact-item-icon span,
footer .social-icon i,
footer .social-icon span {
    position: relative;
    z-index: 3;
}

footer .contact-item-title {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 24px;
    font-weight: normal;
    color: #ccc;
}

html[dir="rtl"] footer .contact-item-title {
    font-size: 0.9rem;
}

html[dir="ltr"] footer .contact-item-title {
    font-size: 1rem;
}

footer .contact-item-info {
    color: #919eab;
    font-size: 0.8rem;
    padding: 3px 0 0 0;
}

footer .facebook.social-icon:before {
    background-color:#3B5998;
}

footer .twitter.social-icon:before {
    background-color:#1DA1F2;
}

footer .youtube.social-icon:before {
    background-color:#E62117;
}

footer .instagram.social-icon:before {
    background-color:#9F2EB8;
}

footer .linkedin.social-icon:before {
    background-color:#0A66C2;
}

// footer .social-icon {
//     display: inline-block;
//     background-color: #25334F;
//     position: relative;
//     width: 30px;
//     height: 30px;
//     margin-left: 15px;
//     -webkit-border-radius: 4px;
//     -moz-border-radius: 4px;
//     -ms-border-radius: 4px;
//     -o-border-radius: 4px;
//     border-radius: 4px;
//     -webkit-transform: rotate(45deg);
//     -moz-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     -o-transform: rotate(45deg);
//     transform: rotate(45deg);
// }

// footer .social-icon .mdi,
// footer .social-icon .iconify {
//     color: #fff;
//     position: absolute;
//     top: 7px;
//     left: 8px;
//     -webkit-transform: rotate(-45deg);
//     -moz-transform: rotate(-45deg);
//     -ms-transform: rotate(-45deg);
//     -o-transform: rotate(-45deg);
//     transform: rotate(-45deg);
// }
