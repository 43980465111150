@import "./../../../resources/styles/sass/variables.scss";

.nav-shadow {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.navbar-light .navbar-nav .nav-link {
    color: #444 !important;
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: $blue !important;
}

.dropdown-toggle::after {
    margin-left: .4em;
    margin-right: .4em;
}

.navbar-nav .dropdown-menu.show {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

html[dir="ltr"] .navbar-nav .dropdown-menu {
    right: 0;
    left: auto;
    text-align: left;
    min-width: 13rem;
}

html[dir="rtl"] .navbar-nav .dropdown-menu {
    right: auto;
    left: 0;
    text-align: right;
    min-width: 13rem;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
}
