/*** Intro Section ***/

.intro-wrapper {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

html[dir="rtl"] .intro-wrapper {
    background-image: url("./../../../resources/images/intro-background-rtl.jpg");
    background-position: left top;
}

html[dir="ltr"] .intro-wrapper {
    background-image: url("./../../../resources/images/intro-background-ltr.jpg");
    background-position: right top;
}

.intro-content {
    min-height: 100%;
    padding-top: 70px;
    padding-bottom: 0px;
    background-color: rgba(0, 0, 0, .3);
    color: #fff;
}

.intro-content .down_arrow {
    display: block;
}

.intro-content .down_arrow .fa:hover {
    color: #fff !important;
}

/***********************/

.intro-wrapper-v2 {
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    // min-height: 500px;
}

html[dir="rtl"] .intro-wrapper-v2 {
    // background-image: url("./../../../resources/images/bg-3.png");
    // background-image: url("./../../../resources/images/intro-bg-v2-rtl.jpg");
}

html[dir="ltr"] .intro-wrapper-v2 {
    // background-image: url("./../../../resources/images/intro-bg-v2-ltr.jpg");
}

.main-sidebar {
    background-color: #fff;
    border-right: 1px solid #ddd;
    box-shadow: 5px 0 5px -2px rgba(0, 0, 0, .07);
    // display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 250px;
    z-index: 9999;
}

@media (min-width : 480px) {
    /* 480px and up */
}

@media (min-width : 768px) {
    /* 768px and up */
}
