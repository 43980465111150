@import "./_intro-section.scss";
@import "./open-loan-requests.scss";

.sidebar-navlink {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    display: block;
    font-size: 0.9rem;
    margin: 0;
    padding: 10px 15px;
    text-decoration: none !important;
    color: #000;
}

.sidebar-navlink:hover {
    background-color: rgba(0, 0, 0, 0.03);
    color: #000;
}

.home-navbar-link {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    // display: block;
    font-size: 0.82rem;
    margin: 0 15px;
    padding: 10px 0;
    text-decoration: none !important;
    color: #777;
    // color: blue;
}

.nav-shadow {
    box-shadow: none;
}
