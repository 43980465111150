.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"  !important;
    font-size: 0.9rem !important;
    direction: ltr;
}

div.react-datepicker-time__input {
    width: 100% !important;
}

input.react-datepicker-time__input {
    width: 100% !important;
    margin-left: 0 !important;
    padding: 7px !important;
    border: 1px solid #ccc !important;
    border-radius: 2px !important;
    color: brown !important;
}

.react-datepicker__close-icon::after {
    background-color: #007bff !important;
    font-size: 18px !important;
    height: 21px !important;
    width: 21px !important;
    padding: 0px 3px 3px 3px !important;
}

.react-datepicker__header--custom select {
    border: 1px solid #ccc !important;
    padding: 6px 3px !important;
    border-radius: 2px !important;
    color: brown !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
    border-radius: 50% !important;
    background-color: #007bff !important;
}

.react-datepicker__year-text--keyboard-selected {
    border-radius: 4px !important;
    background-color: #007bff !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
    border-radius: 50% !important;
    background-color: #007bff !important;
}

.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 4px !important;
    background-color: #007bff !important;
}

.react-datepicker__day--selected:hover, 
.react-datepicker__day--in-selecting-range:hover, 
.react-datepicker__day--in-range:hover, 
.react-datepicker__month-text--selected:hover, 
.react-datepicker__month-text--in-selecting-range:hover, 
.react-datepicker__month-text--in-range:hover, 
.react-datepicker__quarter-text--selected:hover, 
.react-datepicker__quarter-text--in-selecting-range:hover, 
.react-datepicker__quarter-text--in-range:hover, 
.react-datepicker__year-text--selected:hover, 
.react-datepicker__year-text--in-selecting-range:hover, 
.react-datepicker__year-text--in-range:hover {
    background-color: #007bff !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
    border-radius: 50% !important;
    background-color: #eee !important;
}

.react-datepicker__year-text:hover {
    border-radius: 4px !important;
    background-color: #eee !important;
}

.react-datepicker__year-wrapper {
    max-width: 205px !important;
}