section.open_loan_requests {
    // background: #007bff;
    // background: linear-gradient(to right, #0062E6, #33AEFF);
}

.open_loan_requests .card {
    border: none;
    border-radius: 0.8rem;
    // transition: all 0.2s;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.open_loan_requests .card:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.open_loan_requests hr {
    margin: 1.5rem 0;
}

.open_loan_requests .card-title {
    margin: 0.5rem 0 0.7rem;
    font-size: 1rem;
    font-weight: bold;
}

.open_loan_requests .card-price {
    font-size: 2.5rem;
    margin: 0;
}

.open_loan_requests .card-price .period {
    font-size: 0.8rem;
}

.open_loan_requests ul hr {
    margin-top: 12px;
    margin-bottom: 12px;
}

.open_loan_requests .text-muted {
    opacity: 0.7;
}

.open_loan_requests .btn {
    border-radius: 5rem;
    font-weight: bold;
    padding: 0.7rem;
    transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
    .open_loan_requests .card:hover {
        margin-top: -0.5rem;
        margin-bottom: 1rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }

    .open_loan_requests .card:hover .btn {
        opacity: 1;
    }
}
