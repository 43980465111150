@import "./_variables.scss";

body {
    // font-family: "Droid Arabic Kufi", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    background-color: #edf1fd;
    font-size: 0.94rem;
    font-family: $default-font !important;
}

.body-bgc {
    background-color: #edf1fd !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// a:focus,
a:hover,
a:active {
    outline: none !important;
}

.table th {
    padding: 1.2rem .50rem;
}

.table td {
    padding: 1.5rem .50rem;
}

.title-border {
    display: inline-block;
    margin-top: 15px;
    width: 50px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: $indigo;
}

.dropdown-item.active svg,
.dropdown-item:active svg {
    color: #fff !important;
}

.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 5px;
    margin: 0;
    overflow: hidden;
}

input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aaa;
    /* Firefox */
    opacity: 1;
}

input::-webkit-input-placeholder {
    color: #aaa !important;
}

input:-moz-placeholder {
    /* Firefox 18- */
    color: #aaa !important;
}

input::-moz-placeholder {
    /* Firefox 19+ */
    color: #aaa !important;
}

input:-ms-input-placeholder {
    color: #aaa !important;
}

textarea {
    width: 100% !important;
}

.list-group-item.active .list-group-item-hint {
    color: #99c1de !important;
}

.x-w-100p {
    width: 100% !important;
}

.x-h-100p {
    height: 100% !important;
}

// h1, h2, h3, h4, h5, h6, .head-color {
//     color: #183a9e !important;
// }

h3, .h3 {
    font-size: 1.7rem !important;
}

.head-color {
    // color: #031b4e !important;
    // color: navy !important;
    color: #183a9e !important;
}

.progress {
    height: 5px;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: 4px;
    box-shadow: inset 0 0.1rem 0.1rem rgba(0,0,0,.1);
}

.custom_select {
    border-color: #ccc;
    border-radius: 4px !important;
    border: 1px solid #d7d7d7;
    color: #777;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    // height: calc(1.5em + .75rem + 2px);
    height: 2.5rem;
    line-height: 1.5;
    min-width: 125px;
    padding: .5rem .2rem;
    width: 100%;
}

html[dir="rtl"] .custom_select {
    font-size: .85rem;
}

.btn-grad {
    // background-image: linear-gradient(to right, #fc00ff  1%, #00dbde  100%)
    background-image: linear-gradient(to right, #00dbde  1%, #fc00ff  100%)
}

// html[dir="rtl"] .page-item:first-child .page-link {
//     margin-left: 0;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;

//     margin-right: 0;
//     border-top-right-radius: .25rem;
//     border-bottom-right-radius: .25rem;
// }

// html[dir="rtl"] .page-link {
//     margin-left: 0;
//     margin-right: -1px;
// }

// html[dir="ltr"] .page-item:first-child .page-link {
//     margin-right: 0;
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;

//     margin-left: 0;
//     border-top-left-radius: .25rem;
//     border-bottom-left-radius: .25rem;
// }

// html[dir="ltr"] .page-link {
//     margin-right: 0;
//     margin-left: -1px;
// }

html[dir="rtl"] .page-link {
    margin-left: auto;
    margin-right: -1px;
}
html[dir="rtl"] .page-item:first-child .page-link {
    margin-left: auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    margin-right: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
html[dir="rtl"] .page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

html[dir="ltr"] .page-link {
    margin-left: -1px;
    margin-right: auto;
}
html[dir="ltr"] .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;

    margin-right: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
html[dir="ltr"] .page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-select {
    font-size: 0.9rem;
}

html[dir="rtl"] .form-check-label {
    margin: 0 1.5rem 0 0;
}

html[dir="ltr"] .form-check-label {
    margin: 0 0 0 .5rem;
}

html[dir="rtl"] .custom-select {
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") left .75rem center/8px 10px no-repeat;
    padding: .375rem .75rem .375rem 1.75rem;
}

html[dir="ltr"] .custom-select {
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat;
    padding: .375rem 1.75rem .375rem .75rem;
}

.apexcharts-text tspan {
    font-family: 'Droid Arabic Kufi', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

html[dir="rtl"] .alert-dismissible {
    padding-right: 1.25rem;
    padding-left: 4rem;
}

html[dir="rtl"] .alert-dismissible .close {
    right: auto;
    left: 0;
}

.gradient-bg {
    background-image: linear-gradient(rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 1));
}

.bg-pattern {
    // background: #fff url("data:image/svg+xml,%3Csvg viewBox='0 0 121 121' height='121' width='121' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23EFF1F3' fill-rule='evenodd'%3E%3Cpath d='M1 60h60l-.024-60H62v60h59v1H62v59h-1V61H1zM0 0h1v121H0zM1 120h120v1H1z'/%3E%3C/g%3E%3C/svg%3E") top center !important;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 121 121' height='121' width='121' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f6f6f6' fill-rule='evenodd'%3E%3Cpath d='M1 60h60l-.024-60H62v60h59v1H62v59h-1V61H1zM0 0h1v121H0zM1 120h120v1H1z'/%3E%3C/g%3E%3C/svg%3E") !important;
    background-position: top center;
    background-repeat: repeat;
}

.border {
    border-color: #dee2e6;
    border-style: solid;
    border-width: 1px;
}

.border-ececec {
    border-color: #ececec;
    border-style: solid;
    border-width: 1px;
}

.border-bottom {
    border-bottom-color: #dee2e6;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

/* The following rules belonsg to the react-select component */
.css-1s2u09g-control {
    border-radius: 2px !important;
    min-height: 32px !important;
}

.css-1rhbuit-multiValue {
    background-color: rgba(0, 82, 204, 0.1) !important;
}

.css-12jo7m5 {
    color: rgba(0, 82, 204, 1) !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.css-xb97g8:hover {
    background-color: rgba(0, 82, 204, 0.7) !important;
    color: #fff !important;;
}

.css-tlfecz-indicatorContainer {
    padding: 5px !important;
}

.alert-custom {
    color: #000;
    background-color: #edf1fd;
    border-color: #ddd;
}

.link-color {
    // color: #3490dc;
    color: #007bff;
}
.link-color:active,
.link-color:hover {
    color: #005abb;
}

.link-like {
    // color: #3490dc;
    color: #007bff;
}
.link-like:active,
.link-like:hover {
    // color: #007bff;
    color: #005abb;
}
.link-like:hover {
    text-decoration: underline;
}

html[dir="rtl"] .input-group input.form-control {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
    border-bottom-left-radius: 0 !important;
}
html[dir="rtl"] .input-group .input-group-append button.btn {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 2px !important;
    margin-right: -1px;
}
html[dir="ltr"] .input-group input.form-control {
    border-top-left-radius: 2px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 2px !important;
}
html[dir="ltr"] .input-group .input-group-append button.btn {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
    border-bottom-left-radius: 0 !important;
}

html[dir="rtl"] .ltr_grouped_field_and_button .input-group .input-group-append button.btn,
html[dir="ltr"] .ltr_grouped_field_and_button .input-group .input-group-append button.btn {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 0 !important;
}
html[dir="rtl"] .ltr_grouped_field_and_button .input-group .input-group-append button.btn {
    margin-right: -1px;
}
html[dir="rtl"] .ltr_grouped_field_and_button .input-group input.form-control,
html[dir="ltr"] .ltr_grouped_field_and_button .input-group input.form-control {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 4px !important;
}
