/* Body */
$body-bg: #fff;

/* Typography */
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$bootstrap-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$amazonember-font: "Amazon Ember";
$rubik-font: "Rubik";
$droidarabickufi-font: "Droid Arabic Kufi";
$droidarabicnaskh-font: "Droid Arabic Naskh";
$jozoor-font: "JF Flat Regular";
$default-font: $rubik-font, $amazonember-font, $bootstrap-font;

/* Colors */
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
