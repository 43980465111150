@import "./_variables.scss";

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap');

@font-face {
    font-family: "Amazon Ember";
    src: url("./../../fonts/amazon/ember/AmazonEmber_Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: "Amazon Ember";
//     src: url("./../../fonts/amazon/ember/AmazonEmber_Bold.woff2") format("woff2");
//     font-weight: 700;
//     font-style: normal;
// }

// @font-face {
//     font-family: "JF Flat Regular";
//     src: url("./../../fonts/jozoor/JF-Flat-regular.eot");
//     src: url("./../../fonts/jozoor/JF-Flat-regular.eot?#iefix") format("embedded-opentype"),
//         url("./../../fonts/jozoor/JF-Flat-regular.svg#JF Flat Regular") format("svg"),
//         url("./../../fonts/jozoor/JF-Flat-regular.woff") format("woff"),
//         url("./../../fonts/jozoor/JF-Flat-regular.ttf") format("truetype");
//     font-weight: normal;
//     font-style: normal;
// }

/*
 * Droid Arabic Kufi (Arabic)
 * http://www.google.com/fonts/earlyaccess
 * https: //fonts.googleapis.com/earlyaccess/droidarabickufi.css
 */
@font-face {
    font-family: 'Droid Arabic Kufi';
    font-style: normal;
    font-weight: 400;
    src: url("./../../fonts/droid-arabic-kufi/DroidKufi-Regular.eot");
    src: url("./../../fonts/droid-arabic-kufi/DroidKufi-Regular.eot?#iefix") format("embedded-opentype"),
        url("./../../fonts/droid-arabic-kufi/DroidKufi-Regular.woff2") format("woff2"),
        url("./../../fonts/droid-arabic-kufi/DroidKufi-Regular.woff") format("woff"),
        url("./../../fonts/droid-arabic-kufi/DroidKufi-Regular.ttf") format("truetype");
}

// @font-face {
//     font-family: "Droid Arabic Kufi";
//     font-style: normal;
//     font-weight: 700;
//     src: url("./../../fonts/droid-arabic-kufi/DroidKufi-Bold.eot");
//     src: url("./../../fonts/droid-arabic-kufi/DroidKufi-Bold.eot?#iefix") format("embedded-opentype"),
//         url("./../../fonts/droid-arabic-kufi/DroidKufi-Bold.woff2") format("woff2"),
//         url("./../../fonts/droid-arabic-kufi/DroidKufi-Bold.woff") format("woff"),
//         url("./../../fonts/droid-arabic-kufi/DroidKufi-Bold.ttf") format("truetype");
// }

.ff-bootstrap {
    font-family: $bootstrap-font !important;
}

.ff-amazonember {
    font-family: $amazonember-font, $bootstrap-font !important;
}

.ff-rubik {
    font-family: $rubik-font, $bootstrap-font !important;
}

.ff-droidarabickufi {
    font-family: $droidarabickufi-font, $bootstrap-font !important;
}

.ff-default {
    font-family: $default-font !important;
}

// .ff-droidarabicnaskh {
//     font-family: $droidarabicnaskh-font, $bootstrap-font;
// }

// .ff-jozoor {
//     font-family: $jozoor-font, $bootstrap-font;
// }

.x-fz-06rem {
    font-size: 0.6rem !important;
}

.x-fz-065rem {
    font-size: 0.65rem !important;
}

.x-fz-07rem {
    font-size: 0.7rem !important;
}

.x-fz-072rem {
    font-size: 0.72rem !important;
}

.x-fz-075rem {
    font-size: 0.75rem !important;
}

.x-fz-076rem {
    font-size: 0.76rem !important;
}

.x-fz-077rem {
    font-size: 0.77rem !important;
}

.x-fz-078rem {
    font-size: 0.78rem !important;
}

.x-fz-079rem {
    font-size: 0.79rem !important;
}

.x-fz-08rem {
    font-size: 0.8rem !important;
}

.x-fz-082rem {
    font-size: 0.82rem !important;
}

.x-fz-083rem {
    font-size: 0.83rem !important;
}

.x-fz-084rem {
    font-size: 0.84rem !important;
}

.x-fz-085rem {
    font-size: 0.85rem !important;
}

.x-fz-087rem {
    font-size: 0.87rem !important;
}

.x-fz-09rem {
    font-size: 0.9rem !important;
}

.x-fz-091rem {
    font-size: 0.91rem !important;
}

.x-fz-092rem {
    font-size: 0.92rem !important;
}

.x-fz-094rem {
    font-size: 0.94rem !important;
}

.x-fz-095rem {
    font-size: 0.95rem !important;
}

.x-fz-097rem {
    font-size: 0.97rem !important;
}

.x-fz-1rem {
    font-size: 1rem !important;
}

.x-fz-11rem {
    font-size: 1.1rem !important;
}

.x-fz-12rem {
    font-size: 1.2rem !important;
}

.x-fz-13rem {
    font-size: 1.3rem !important;
}

.x-fz-14rem {
    font-size: 1.4rem !important;
}

.x-fz-15rem {
    font-size: 1.5rem !important;
}

.x-fz-16rem {
    font-size: 1.6rem !important;
}

.x-fz-165rem {
    font-size: 1.65rem !important;
}

.x-fz-17rem {
    font-size: 1.7rem !important;
}

.x-fz-175rem {
    font-size: 1.75rem !important;
}

.x-fz-2rem {
    font-size: 2rem !important;
}

.x-fz-21rem {
    font-size: 2.1rem !important;
}

.x-fz-22rem {
    font-size: 2.2rem !important;
}

.x-fz-23rem {
    font-size: 2.3rem !important;
}

.x-fz-25rem {
    font-size: 2.5rem !important;
}

.x-fz-3rem {
    font-size: 3rem !important;
}

.t-shadow {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.x-c-indigo {
    color: $indigo;
}

.x-bdbc-indigo {
    border-bottom-color: $indigo;
}

.x-c-orangered {
    color: orangered !important;
}

.x-bgc-fafafa {
    background-color: #fafafa !important;
}

.x-bgc-f8f8f8 {
    background-color: #f8f8f8 !important;
}

.x-bgc-f5f6f7 {
    background-color: #f5f6f7 !important;
}

.x-bgc-edf1fd {
    background-color: #edf1fd !important;
}

.x-bgc-blueviolet {
    background-color: BlueViolet !important;
}

.x-c-blue {
    color: #007bff !important;
}

.x-c-blueviolet {
    color: BlueViolet !important;
}

.x-bgc-chocolate {
    background-color: Chocolate !important;
}

.x-c-chocolate {
    color: Chocolate !important;
}

.x-bgc-lightseagreen {
    background-color: LightSeaGreen !important;
}

.x-c-lightseagreen {
    color: LightSeaGreen !important;
}

.x-bgc-mediumorchid {
    background-color: MediumOrchid !important;
}

.x-c-mediumorchid {
    color: MediumOrchid !important;
}

.x-bgc-lightslategrey {
    background-color: LightSlateGrey !important;
}

.x-c-lightslategrey {
    color: LightSlateGrey !important;
}

.bg-danger {
    background-color: #f62d51 !important;
}

.text-danger {
    color: #f62d51 !important;
}

.bg-success {
    // background-color: #39c449!important;
    background-color: #10a37f!important;
}

.text-success {
    // color: #39c449 !important;
    color: #10a37f !important;
}

.green_bgc {
    // background: #00ac90 !important;
    // background: #00cc6f !important;

    // background: #20C178 !important;
    // border-color: #20C178 !important;

    background: #10a37f !important;
    border-color: #10a37f !important;
}

.blue_bgc {
    background: #007bff;
    background: linear-gradient(to right, #0062E6, #33AEFF);
}

.green_button {
    background-color: #10a37f;
    border-color: transparent;
    color: #fff;
}

.purple_button {
    background-color: #A358DF;
    border-color: transparent;
    color: #fff;
}

.red_button {
    background-color: #FB275D;
    border-color: transparent;
    color: #fff;
}

.gold_button {
    background-color: #FFCC00;
    border-color: transparent;
    color: #fff;
}

.green_blue_button {
    background: linear-gradient(90deg, #00C798, #0EA0FD);
    color: #fff;
}

.red_orange_button {
    background: linear-gradient(90deg, #E80A89 0%, #F15B2A 100%);
    color: #fff;
}

.color_red {
    color: #FB275D !important;
}

.color_purple {
    color: #A358DF !important;
}

.color_green {
    color: #00CC6F !important;
}

.box-with-shadow {
    -webkit-box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.1);
}

.x-mih-500 {
    min-height: 500px !important;
}

.x-bdc-e3e3e3 {
    border-color: #e3e3e3 !important;
}

.shadow-md {
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,0.1), 0 0px 0 1px rgba(10,10,10,0.02);
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}