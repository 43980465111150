// ==============================================
// Custom Variables
// ==============================================
$dot-width: 8px !default;
$dot-height: 8px !default;
// $dot-radius: $dot-width/2 !default;
$dot-radius: calc($dot-width / 2) !default;

$dot-color: #fff !default;
$dot-bg-color: $dot-color !default;
$dot-before-color: $dot-color !default;
$dot-after-color: $dot-color !default;

$dot-spacing: $dot-width + calc($dot-width / 2) !default;


// ==============================================
// Custom Mixins
// ==============================================
@mixin dot($width: $dot-width,
    $height: $dot-height,
    $radius: $dot-radius,
    $bg-color: $dot-bg-color,
    $color: $dot-color) {
    width: $width;
    height: $height;
    border-radius: $radius;
    background-color: $bg-color;
    color: $color;
}


// ==============================================
// Custom Style
// ==============================================
.button-loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 5px;
    margin: 0;
    overflow: hidden;
}


// ==============================================
// The original style of the three-dot loader
// NOTE: they MUST imported at the end of this file
// ==============================================
@import "~three-dots/sass/_variables.scss";
@import "~three-dots/sass/_mixins.scss";
// @import "~three-dots/sass/_dot-typing.scss";


/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */

$left-pos: -9999px;
$x1: -$left-pos - $dot-spacing;
$x2: -$left-pos;
$x3: -$left-pos + $dot-spacing;

.button-loader-dot-typing {
  position: relative;
  left: $left-pos;

  @include dot;

  box-shadow:
    $x1 0 0 0 $dot-before-color,
    $x2 0 0 0 $dot-color,
    $x3 0 0 0 $dot-after-color;
  animation: button-loader-dot-typing 1.5s infinite linear;
}

@keyframes button-loader-dot-typing {
  0% {
    box-shadow:
      $x1 0 0 0 $dot-before-color,
      $x2 0 0 0 $dot-color,
      $x3 0 0 0 $dot-after-color;
  }

  16.667% {
    box-shadow:
      $x1 -10px 0 0 $dot-before-color,
      $x2 0 0 0 $dot-color,
      $x3 0 0 0 $dot-after-color;
  }

  33.333% {
    box-shadow:
      $x1 0 0 0 $dot-before-color,
      $x2 0 0 0 $dot-color,
      $x3 0 0 0 $dot-after-color;
  }

  50% {
    box-shadow:
      $x1 0 0 0 $dot-before-color,
      $x2 -10px 0 0 $dot-color,
      $x3 0 0 0 $dot-after-color;
  }

  66.667% {
    box-shadow:
      $x1 0 0 0 $dot-before-color,
      $x2 0 0 0 $dot-color,
      $x3 0 0 0 $dot-after-color;
  }

  83.333% {
    box-shadow:
      $x1 0 0 0 $dot-before-color,
      $x2 0 0 0 $dot-color,
      $x3 -10px 0 0 $dot-after-color;
  }

  100% {
    box-shadow:
      $x1 0 0 0 $dot-before-color,
      $x2 0 0 0 $dot-color,
      $x3 0 0 0 $dot-after-color;
  }
}
